import React from 'react'
import Slider from '../../../Base/SliderTab'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';

const AirlineMatrix = ({ matrix, scrollIndex, onFilterAirline,
    flightWay, currentSegmentSeq }) => {
    let anyTransAndStop0 = matrix.getIn(['minPrice', '0', 'airlineCodes']).size,
        anyTransAndStop1 = matrix.getIn(['minPrice', '1', 'airlineCodes']).size,
        anyTransAndStop2 = matrix.getIn(['minPrice', '2', 'airlineCodes']).size,
        getTabTitle = () => {
            let data = matrix.get('data'),
                minPrice = matrix.get('minPrice'),
                getPriceLabel = (item, transCount) => item.get(transCount) ? (
                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.MATRIX_ITEM, LIST_UBT_KEY_TYPES.MATRIX_ITEM, flightWay, currentSegmentSeq, '航司_中转次数', `${item.get('code')}_${transCount}`)}>
                        <div u_key='airline_matrix_entry' u_remark={`航司矩阵[airline:${item.get('name')},transCount:${transCount}]`}
                            onClick={onFilterAirline.bind(this, item.get('code'), +transCount)}
                            className={minPrice.getIn([transCount, 'airlineCodes']).includes(item.get('code')) ? 'lowprice price' : 'price'}>
                            <dfn>&yen;</dfn>{item.get(transCount)}
                        </div>
                    </UbtBoundary>
                ) : <div className="price no-price">&nbsp;</div>,
                children = data.map((item, index) => {
                    return (
                        <li key={index}>
                            <div className="airline">
                                <img className="logo" src={`//pic.c-ctrip.com/flight_intl/airline_logo/32x32/${item.get('code', '').toUpperCase() || 'default'}.png`} alt={item.get('name')}/>{item.get('name')}
                            </div>

                            {anyTransAndStop0 ? getPriceLabel(item, '0') : null}
                            {anyTransAndStop1 ? getPriceLabel(item, '1') : null}
                            {anyTransAndStop2 ? getPriceLabel(item, '2') : null}
                        </li>)
                })

            return <ul className='detail'>{children}</ul>
        },
        genTransCountEntry = (title, count) => {
            return (
                <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.MATRIX_ITEM, LIST_UBT_KEY_TYPES.MATRIX_ITEM, flightWay, currentSegmentSeq, '中转次数', title)}>
                    <div u_key='airline_matrix_entry' u_remark={`航司矩阵[${title}]`} onClick={onFilterAirline.bind(this, '', count)} className="flt-type">{title}</div>
                </UbtBoundary>
            )
        },
        getTabBox = () => {
            return (
                <div className="flt-type-box">
                    <div className="flt-type empty"></div>

                    {anyTransAndStop0 ? genTransCountEntry('直飞', 0) : null}
                    {anyTransAndStop1 ? genTransCountEntry('1程中转', 1) : null}
                    {anyTransAndStop2 ? genTransCountEntry('2程中转及以上', 2) : null}
                </div>
            )
        }

    return (
        <Slider maxItem={7} parentClassName='airline-price' getTabTitle={getTabTitle()} getTabBox={getTabBox()}
            tabTitleLength={matrix.get('data').size} preActiveClass="pre-page" nextActiveClass="next-page"
            preDisabledClass="pre-page disabled" nextDisabledClass="next-page disabled" leftMargin={0} handleCurrentStart={(_a) => { }}
            currentStart={scrollIndex} preContent={<i className="arrow-left-bold"></i>} nextContent={<i className="arrow-right-bold"></i>}
            containerStyle={{ position: 'relative' }} tabClassName='airline-tbl' />
    )
}

export default AirlineMatrix
