import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';

import AirlineMatrix from '../../../../components/list/top/airlineMatrix';
import { getAirlineMatrixSelector } from './airlineMatrixSelector';
import { genResetActiveKeys } from '../../../../actions/list/filter';
import { getFilterTypes, TRANS_COUNT_TYPE } from '../../../../constants/list/enums/filter';
import {
	filterShowAirlineMatrixSelector,
	currentSegmentSeqSelector,
	getFlightWaySelector,
} from '../../result/flight/baseSelector';

/**
 * 单个航司项宽度
 */
const SINGLE_AIRLINE_WIDTH = 128;

class AirlineMatrixContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scrollIndex: 0,
		};
	}

	onFilterAirline = (airlineCode, count) => {
		const { activeRoundTabIndex, alwaysShow } = this.props,
			splitRound = alwaysShow && activeRoundTabIndex === 1; // 是否往返tab的返程

		this.props.onFilterAirline(airlineCode, count, splitRound);
	};

	componentDidUpdate(prevProps) {
		const { activeRoundTabIndex } = prevProps;
		if (activeRoundTabIndex !== this.props.activeRoundTabIndex) {
			// 不相等说明去程/返程的切换，此时重置 scrollIndex
			this.setState({ scrollIndex: 0 });
		}
	}

	render() {
		const { showAirlineMatrix, alwaysShow } = this.props;

		return (
			<CSSTransitionGroup
				transitionName="airline-matrix"
				transitionEnterTimeout={200}
				transitionLeaveTimeout={200}
			>
				{showAirlineMatrix || alwaysShow ? (
					<AirlineMatrix
						key="airline-matrix"
						scrollIndex={this.state.scrollIndex}
						singleAirlineWidth={SINGLE_AIRLINE_WIDTH}
						{...this.props}
						onFilterAirline={this.onFilterAirline}
					/>
				) : null}
			</CSSTransitionGroup>
		);
	}
}

const mapStateToProps = (state, _ownProps) => {
	return {
		matrix: getAirlineMatrixSelector(state),
		showAirlineMatrix: filterShowAirlineMatrixSelector(state),
		currentSegmentSeq: currentSegmentSeqSelector(state),
		flightWay: getFlightWaySelector(state),
	};
};

const mapDispatchToProps = (dispatch, _ownProps) => {
	return {
		onFilterAirline: (airlineCode, count, splitRound) => {
			const filterTypes = getFilterTypes();
			dispatch(
				genResetActiveKeys(
					{
						[filterTypes.AIRLINE.key]: airlineCode ? [airlineCode] : [],
						[filterTypes.TRANS_COUNT.key]:
							count === 0
								? [TRANS_COUNT_TYPE.ZERO]
								: count === 1
								? [TRANS_COUNT_TYPE.ONE]
								: [TRANS_COUNT_TYPE.MORE],
					},
					splitRound
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AirlineMatrixContainer);
